import Vue from 'vue'
import VueWaypoint from 'vue-waypoint'
import Clipboard from 'v-clipboard'
import axios from 'axios'
import VueFriendlyIframe from 'vue-friendly-iframe'
import ImgProxy from '../libraries/ImgProxy.js'

Vue.use(VueFriendlyIframe)
Vue.use(Clipboard)
Vue.use(VueWaypoint)
const cfg = require(`../configs/${process.env.NODE_ENV}.json`)

/* Global imgproxy */
const imgProxy = new ImgProxy(cfg.imgProxy)
Vue.prototype.$imgProxy = imgProxy

const newImgProxy = new ImgProxy(cfg.newImgProxy)
Vue.prototype.$newImgProxy = newImgProxy

function checkCanFreeDesign (value) {
  const canFreeDesignIds = [24, 25, 48, 30, 31, 49, 50, 51, 52, 56, 57, 58, 59, 66, 73, 76, 77, 78, 79, 80, 'pouch', 'shelf', 'table', 96, 97, 98, 99]
  return canFreeDesignIds.includes(value)
}

function toDetailPage (pkt, id) {
  const routerData = {}
  if (pkt === 4) {
    routerData.name = 'products-detail-id'
    routerData.params = { id }
  } else {
    routerData.name = 'customize-product-design-id'
    routerData.params = { design: true, id }
  }
  return routerData
}

function formatPrice (value) {
  if (!value) {
    return '0.00'
  }
  return parseFloat(value).toFixed(2)
}

function calculateExcludeVat (value = null, vat = 1.07) {
  const price = parseFloat(value) || 0
  return (price / vat)
}

function calculateExcludeVatService (value = null) {
  const price = parseFloat(value) || 0
  return (price * 0.03)
}

function roundToDecimals (num, decimals) {
  return Number(num.toFixed(decimals))
}

function formatPriceNumber (value) {
  if (!value) {
    return '0'
  }
  return parseFloat(value).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

function formatPriceFloat (value) {
  if (!value) {
    return '0.00'
  }
  return parseFloat(value).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

async function getFlute (paperId) {
  const res = await axios.get(`${cfg.axios.apiBaseURL}corrugate/get-flute-from-paper/${paperId}`)
  const flute = res.data.result ? res.data.result : null
  return flute
}

Vue.prototype.$formatPrice = string => formatPrice(string)
Vue.prototype.$checkCanFreeDesign = val => checkCanFreeDesign(val)
Vue.prototype.$formatPriceFloat = string => formatPriceFloat(string)
Vue.prototype.$formatPriceNumber = string => formatPriceNumber(string)
Vue.prototype.$calculateExcludeVat = (vat, value) => calculateExcludeVat(vat, value)
Vue.prototype.$calculateExcludeVatService = value => calculateExcludeVatService(value)
Vue.prototype.$roundToDecimals = value => roundToDecimals(value)
Vue.prototype.$getFlute = val => getFlute(val)
Vue.prototype.$toDetailPage = (pkg, id) => toDetailPage(pkg, id)
Vue.prototype.$urlS3 = (ctx, url) => {
  if (typeof url !== 'undefined' && url !== '') {
    const pattern = /^((http|https|ftp):\/\/)/
    if (pattern.test(url)) {
      return url
    } else {
      return ctx.$store.state.s3 + url
    }
  }
}
Vue.prototype.$baseurl = (url) => {
  return cfg.axios.apiBaseURL + url
}

Vue.prototype.$line = () => {
  return cfg.line
}
Vue.prototype.$lineCRM = () => {
  return cfg.lineCRM
}

Vue.prototype.$pkgTypeThaiName = (packagingTypeId) => {
  if (packagingTypeId === 1) {
    return 'กล่องกระดาษแข็ง'
  } else if (packagingTypeId === 2) {
    return 'สติกเกอร์'
  } else if (packagingTypeId === 3) {
    return 'กล่องลูกฟูก'
  } else if (packagingTypeId === 4) {
    return 'ขวด'
  } else if (packagingTypeId === 5) {
    return 'TAG และปลอก'
  } else {
    return 'บรรจุภัณฑ์'
  }
}

Vue.prototype.$getPatternName = (productType, locopackCode) => {
  let patternName = null
  if (productType === 6) {
    if (locopackCode.toLowerCase().includes('table')) {
      patternName = 'table'
    } else {
      patternName = 'shelf'
    }
  }
  return patternName
}

Vue.prototype.$base64Image = (base64Image) => {
  if (base64Image) {
    return 'data:image/png;base64,' + base64Image
  }
  return null
}

Vue.prototype.$paymentTypeName = (paymentTypeId) => {
  if (paymentTypeId === 1) {
    return 'Transfer'
  } else if (paymentTypeId === 2) {
    return 'Credit'
  } else if (paymentTypeId === 3) {
    return 'Online Banking'
  } else if (paymentTypeId === 4) {
    return 'Installment'
  } else if (paymentTypeId === 5) {
    return 'QR Code'
  } else if (paymentTypeId === 6) {
    return 'SCB Direct Debit'
  } else if (paymentTypeId === 7) {
    return 'QR CS'
  } else {
    return ''
  }
}
